import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const RichTextEditorStyled = styled.div`
  position: relative;

  .editor-wrapper {
    padding-top: 40px; // Adjust based on your toolbar height
  }

  .sticky-toolbar {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000;
    padding: 10px 0;
    border-bottom: 1px solid #e8e8e8;
  }
  .ant-btn {
    &.active {
      color: ${(props) => props.theme.colors.accent};
      border-color: ${(props) => props.theme.colors.accent};
    }
  }
  .remirror-editor {
    margin: 1em 0;
    padding: 0.5em 1em;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 5px;

    &:focus {
      outline: none;
      border-color: #6e6e6e6;
      box-shadow: 0 0 0 2px rgba(52, 53, 70, 0.88);
    }

    p {
      margin: 5px 0;
    }
  }

  .remirror-max-count-exceeded {
    color: ${(props) => props.theme.colors.error};
  }
`
