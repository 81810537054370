import { Form } from 'antd'
import type { FormInstance } from 'antd/es/form'
import * as React from 'react'

import { EstimatesFormTaxTotalSummaryStyled } from './styles'
import { TaxTotalSummary } from '../../TaxTotalSummary'
import { useAppStore } from '../../../stores/appStore'

export interface EstimatesFormTaxTotalSummaryProps {
  form?: FormInstance
  taxes?: any
  currency?: string | null
  showBreakdown?: boolean
  allowTaxRemove?: boolean
  allowClearSubtotal?: boolean
  onEditSubtotal?: () => void
  onClearSubtotal?: () => void
  onTaxRemove?: (tax: any) => void
}

export const EstimatesFormTaxTotalSummary = ({
  form = {} as FormInstance,
  allowClearSubtotal = false,
  onEditSubtotal = () => {},
  onClearSubtotal = () => {},
  ...props
}: EstimatesFormTaxTotalSummaryProps) => {
  const laborCost = useAppStore((state: any) => state.estimateLaborCost)
  const materialCost = useAppStore((state: any) => state.estimateMaterialCost)
  const cost = useAppStore((state: any) => state.estimateCost)
  const total = useAppStore((state: any) => state.estimateTotal)
  const subtotal = useAppStore((state: any) => state.estimateSubtotal)
  const customSubtotal = useAppStore(
    (state: any) => state.estimateCustomSubtotal
  )
  const profit = useAppStore((state: any) => state.estimateProfit)
  const laborProfit = useAppStore((state: any) => state.estimateLaborProfit)
  const materialProfit = useAppStore(
    (state: any) => state.estimateMaterialProfit
  )

  return (
    <EstimatesFormTaxTotalSummaryStyled>
      <TaxTotalSummary
        total={total}
        subtotal={customSubtotal || subtotal}
        profit={profit}
        labor={laborCost}
        materials={materialCost}
        cost={cost}
        laborProfit={laborProfit}
        materialProfit={materialProfit}
        showBreakdown={true}
        allowEditSubtotal={!allowClearSubtotal} // If we allow clearing the subtotal, we should not allow editing it
        allowClearSubtotal={allowClearSubtotal}
        onEditSubtotal={onEditSubtotal}
        onClearSubtotal={onClearSubtotal}
        {...props}
      />
    </EstimatesFormTaxTotalSummaryStyled>
  )
}

EstimatesFormTaxTotalSummary.displayName = 'EstimatesFormTaxTotalSummary'
